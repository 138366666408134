import React from 'react'
import Layout from '../components/layout'
import SearchInput from '../components/SearchInput'
import Seo from "../components/seo"

const EPOEUEPC = () => {

    return (
        <Layout>
            <Seo title="EPO/ AND EU/EPC" />

            <h1 className="title">EPO/ AND EU/EPC</h1>

            <SearchInput />

            <table>
                <tbody>
                    {/* HEADING */}

                    <tr className="thead title">
                        <td colSpan="2">IP searching</td>
                    </tr>

                    <tr>
                        <td>European Patent Register</td>
                        <td><a target="_blank" rel="noreferrer" href="https://register.epo.org/advancedSearch?lng=en">https://register.epo.org/advancedSearch?lng=en</a></td>
                    </tr>

                    <tr>
                        <td>EPO patent databases, registers and gazettes</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.epo.org/service-support/useful-links/databases.html">https://www.epo.org/service-support/useful-links/databases.html</a></td>
                    </tr>

                    {/* HEADING */}

                    <tr className="thead title">
                        <td colSpan="2">Guidance and legislation</td>
                    </tr>

                    <tr>
                        <td>EPO Guidelines for Examination in the European Patent Office</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.epo.org/law-practice/legal-texts/html/guidelines/e/index.htm">https://www.epo.org/law-practice/legal-texts/html/guidelines/e/index.htm</a></td>
                    </tr>

                    <tr>
                        <td>EPO Guidelines for Examination in the European Patent Office</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.epo.org/law-practice/legal-texts/html/guidelines/e/index.htm">https://www.epo.org/law-practice/legal-texts/html/guidelines/e/index.htm</a></td>
                    </tr>

                    <tr>
                        <td>EPC European Patent Convention</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.epo.org/law-practice/legal-texts/epc.html">https://www.epo.org/law-practice/legal-texts/epc.html</a></td>
                    </tr>

                    <tr>
                        <td>EPC Implementing Regulations</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.epo.org/law-practice/legal-texts/html/epc/2020/e/ma2.html">https://www.epo.org/law-practice/legal-texts/html/epc/2020/e/ma2.html</a></td>
                    </tr>

                    <tr>
                        <td>EPC SPC Supplementary protection certificate Regulation</td>
                        <td><a target="_blank" rel="noreferrer" href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32009R0469">https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32009R0469</a></td>
                    </tr>

                    <tr>
                        <td>EPC Biotechnological inventions directive</td>
                        <td><a target="_blank" rel="noreferrer" href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A31998L0044">https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A31998L0044</a></td>
                    </tr>

                    {/* HEADING */}

                    <tr className="thead title">
                        <td colSpan="2">Case Law</td>
                    </tr>

                    <tr>
                        <td>EPO Case Law of the Boards of Appeal of the EPO</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.epo.org/law-practice/case-law-appeals/case-law.html">https://www.epo.org/law-practice/case-law-appeals/case-law.html</a></td>
                    </tr>

                    <tr>
                        <td>ECJ European Court of Justice case search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://curia.europa.eu/juris/recherche.jsf?language=en">https://curia.europa.eu/juris/recherche.jsf?language=en</a></td>
                    </tr>


                    {/* HEADING */}

                    <tr className="thead title">
                        <td colSpan="2">Pharma resources</td>
                    </tr>

                    <tr>
                        <td>EC register of medicinal products</td>
                        <td><a target="_blank" rel="noreferrer" href="https://ec.europa.eu/health/documents/community-register/html/index_en.htm">https://ec.europa.eu/health/documents/community-register/html/index_en.htm</a></td>
                    </tr>

                    <tr>
                        <td>EC Member States registers of nationally-authorised medicinal products</td>
                        <td><a target="_blank" rel="noreferrer" href="https://ec.europa.eu/health/medicinal-products/union-register/member-states-registers-nationally-authorised-medicinal-products_en">https://ec.europa.eu/health/medicinal-products/union-register/member-states-registers-nationally-authorised-medicinal-products_en</a></td>
                    </tr>

                    <tr>
                        <td>EC register of orphan medicinal products</td>
                        <td><a target="_blank" rel="noreferrer" href="https://ec.europa.eu/health/documents/community-register/html/reg_od_act.htm?sort=n">https://ec.europa.eu/health/documents/community-register/html/reg_od_act.htm?sort=n</a></td>
                    </tr>

                    <tr>
                        <td>EMA search of orphan medicines</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.ema.europa.eu/en/medicines/ema_group_types/ema_orphan?search_api_views_fulltext">https://www.ema.europa.eu/en/medicines/ema_group_types/ema_orphan?search_api_views_fulltext</a></td>
                    </tr>

                    {/* HEADING */}

                    <tr className="thead title">
                        <td colSpan="2">Other resources</td>
                    </tr>

                    <tr>
                        <td>EPC Patent offices of member states</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.epo.org/service-support/useful-links/national-offices.html">https://www.epo.org/service-support/useful-links/national-offices.html</a></td>
                    </tr>

                    {/* HEADING */}

                    <tr className="thead title">
                        <td colSpan="2">"EU: UPC Opt Out Search", and UPC Legal Documents</td>
                    </tr>

                    <tr>
                        <td>UPC Opt Out Search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.unified-patent-court.org/en/registry/opt-out/results?case_type&patent_number=EP1309692">UPC Opt Out Search:  Search for Opt-outs | Unified Patent Court (unified-patent-court.org)</a></td>
                    </tr>

                    <tr>
                        <td>UPC Legal Documents</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.unified-patent-court.org/en/court/legal-documents?page=2">Legal Documents | Unified Patent Court (unified-patent-court.org)</a></td>
                    </tr>
                </tbody>
            </table>
        </Layout>
    )
}

export default EPOEUEPC